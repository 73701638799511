var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _vm.UpdateFolderRequestDTO.name != ""
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("ListFolders", {
                    attrs: { active: _vm.information.parent_uuid },
                    model: {
                      value: _vm.UpdateFolderRequestDTO.parent_uuid,
                      callback: function ($$v) {
                        _vm.$set(_vm.UpdateFolderRequestDTO, "parent_uuid", $$v)
                      },
                      expression: "UpdateFolderRequestDTO.parent_uuid",
                    },
                  }),
                  !_vm.edited
                    ? _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                          !_vm.edited
                            ? _c("b-button", { on: { click: _vm.closeBtn } }, [
                                _vm._v(_vm._s(_vm.$t("CLOSE"))),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.edited
                    ? _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c("b-alert", { attrs: { show: "" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("FILESTORAGE.NOTE_CHANGE_SHARED")) +
                                " "
                            ),
                          ]),
                          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                          _c("b-button", { on: { click: _vm.cancelBtn } }, [
                            _vm._v(_vm._s(_vm.$t("CANCEL"))),
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: {
                                disabled: _vm.updateDisabled,
                                variant: "primary",
                              },
                              on: { click: _vm.updateBtn },
                            },
                            [_vm._v(_vm._s(_vm.$t("SAVE")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }