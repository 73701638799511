<template>
  <section class="mt-2" v-if="information">
    <b-overlay :show="loading" rounded="sm">

      <b-table
      :key="'versions_' + redrawKey"
      small
      bordered
      :items="items"
      hover
      class="mt-2 ml-0 pl-0"
      :fields="fields"
      :busy="loading"
    >
      <template v-slot:cell(created_date)="data"><span v-if="data.item.name != '..'">{{
        FormatDateTime(data.item.created_date)
      }}</span></template>
    
      <template v-slot:cell(created_by)="data">
        <UserInfo
          v-if="data.item.created_by"
          :user_uuid="data.item.created_by"
        ></UserInfo>
      </template>

      <template v-slot:cell(id)="data">
        <span v-if="data.item.name != '..'">{{ $t('CREATED') }} {{
          FormatDateTime(data.item.created_date)
        }}</span>
        <UserInfo v-if="data.item.created_by" :user_uuid="data.item.created_by"></UserInfo>
      </template>

      <template v-slot:cell(size)="data">
          {{ bytesToSize(data.item.size) }}
      </template>

      
      <template v-slot:cell(version)="data">
        {{ data.item.version }}
      </template>
    
      
    </b-table>
    <hr class="p-0 m-0 mt-2 mb-2" />

    <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>



    </b-overlay>
  </section>
</template>
<script>
import UserInfo from "../../Layout/UserInfo";
export default {
  components: { UserInfo },
  props: {
    information: {
      type: Object,
      default: null
    },
    user_uuid: {
      type: String,
      default: null,
    },
    file_uuid: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      edited: false,
      redrawIndex: 0,
      loading: false,
      items:[],
      UpdateFolderRequestDTO: null,
      fields: [
          {
            key: "version",
            class: "",
            thClass: "hidden-mobile",
          },
          {
            key: "id",
            class: "hidden-not-mobile",
            thClass: "hidden-mobile",
          },
          {
            key: "name",
            label: this.$t("NAME"),
            class: "text-break w-300 hidden-mobile",
            sortable: false,
          },
          {
            key: "size",
            label: this.$t("SIZE"),
            class: "text-break w-150 hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "created_date",
            label: this.$t("CREATED"),
            class: "text-break w-date hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          },
          {
            key: "created_by",
            label: this.$t("CREATED_BY"),
            class: "text-break w-date hidden-mobile",
            thClass: "hidden-mobile",
            sortable: false,
          }
        ]
    };
  },
  methods: {
    closeBtn() {
      this.$emit("close");
    },
    async fileDownload(versionNumber){   
      let GetFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: this.file_uuid,
        version: versionNumber
      };      
      let FileItemDTO = await this.$http.post(this.user.hostname + "/filestorage/file/info", GetFileRequestDTO);
      let FileBlob = await this.$http.post(this.user.hostname + "/filestorage/file/get", GetFileRequestDTO, {responseType: 'blob'} );
      this.downloadBlob(FileBlob.data, FileItemDTO.data.name);
    },
    ListVersions: function () {
      if(this.file_uuid != "")
      {
        let self = this;
        self.loading = true;
        let ListFileVersionsRequestDTO = {
          organisation_uuid: this.user.information.organisation.organisation_uuid,
          user_uuid: this.user_uuid,
          file_uuid: this.file_uuid,
          search_text: "",
          page: 1,
          limit: 2000        
        }
        this.$http
          .post(
            this.user.hostname + "/filestorage/file/versions/list",
            ListFileVersionsRequestDTO
          )
          .then((response) => {
            self.loading = false;
            self.redrawIndex++;
            self.items = response.data.items;
          })
          .catch(() => {
            self.loading = false;
          });
        }
    },
  },
  computed: {
    redrawKey(){
      return "versions_" + this.redrawIndex;
    },
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    'file_uuid': function() {
      this.ListVersions();
    },
  },
  mounted() {
    this.ListVersions();
  },
};
</script>
<style>
</style>
