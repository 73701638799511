var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.files
    ? _c(
        "section",
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c(
                "form",
                { staticClass: "mt-2" },
                [
                  _vm.files.length == 0
                    ? _c(
                        "FileSelect",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            "max-size": "200",
                            "file-type": _vm.fileExtension,
                          },
                          model: {
                            value: _vm.files,
                            callback: function ($$v) {
                              _vm.files = $$v
                            },
                            expression: "files",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "no-border",
                              attrs: { size: "sm", variant: "secondary" },
                            },
                            [
                              _c("i", { staticClass: "fal fa-plus" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("ADD_FILE")) +
                                  "  (" +
                                  _vm._s(_vm.files.length) +
                                  ")\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.files.length != 0
                ? _c(
                    "span",
                    _vm._l(_vm.files, function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "ms-fontSize-12 mt-2" },
                        [
                          _c("Attachment", {
                            attrs: {
                              "is-error": _vm.checkError(index),
                              percent: _vm.checkPercent(index),
                              item: item,
                              index: index,
                            },
                            on: { removeFile: _vm.removeFile },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _c("b-button", { on: { click: _vm.closeBtn } }, [
                _vm._v(_vm._s(_vm.$t("CLOSE"))),
              ]),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { disabled: _vm.updateDisabled, variant: "primary" },
                  on: { click: _vm.updateBtn },
                },
                [_vm._v(_vm._s(_vm.$t("SAVE")))]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }