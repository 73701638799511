<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <form class="mt-2">
        <FileSelect
          show-drop
          v-model="files"
          class="mt-2"
          max-size="200"
        >
          <b-button size="sm" variant="secondary" class="no-border">
            {{ files.length }}
          </b-button>
        </FileSelect>
      </form>
     
      <span v-if="files.length != 0" :key="'attachments_' + files.length">
        <span
          v-for="(item, index) in files"
          :key="index"
          class="ms-fontSize-12 mt-2"
        >
          <span :class="filesClass(item)">
            <Attachment
              @removeFile="removeFile(index)"
              :is-error="checkError(index)"
              :percent="checkPercent(index)"
              :item="item"
              :index="index"
            ></Attachment>
          </span>
        </span>
      </span>
      
      <div class="error-text" v-if="!AllValidFilesize">
        {{ $t("ERROR.LARGE_FILES") }} {{ bytesToSize(this.GetMaxTotalBytesAllowedToSendFilestorage*1024*1024) }}
      </div>

      <div class="error-text" v-if="!AllValidNames">
        {{ $t("ERROR.TOO_MANY_CHARACTERS") }} {{ 256 }}
      </div>
      
      <div class="error-text" v-if="!allValidText">
        {{ $t("ERROR.CHARACTERS_NOT_ALLOWED") }} {{  notAllowedCharacters }}
      </div>      

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button @click="cancelBtn" id="cancelBtn">{{ $t("CLOSE") }}</b-button>

      <b-button
        :disabled="addDisabled"
        @click="addBtn"
        id="addBtn"
        variant="primary"
        class="btn-fill"
        >{{ $t("SAVE") }}</b-button
      >
    </b-overlay>
  </section>
</template>
<script>
import FileSelect from "../Compose/FileSelect";
import Attachment from "../Compose/Attachment";
export default {
  components: {
    FileSelect,
    Attachment,
  },
  props: {    
    folder_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    }
  },
  data() {
    return {
      files: [],
      fileStatus: [],
      loading: false,
      file_bytes: null,
      name: "",
    };
  },
  methods: {
    ValidateFileSize(bytes)
    { 
      return bytes < (200*1024*1024);
    },
    checkError()
    {      
      return false;
    },
    checkPercent()
    {
      //console.log(index);
      return 0;
    },
    cancelBtn() {
      this.name = "";
      this.$emit("closeAddFile");
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async checkStatus(){
      let stillProgress = this.fileStatus.filter((item) => item.percent == 0 && item.error == false).length != 0;
      if(!stillProgress)
      {
        this.loading = false;
        this.$emit("addedFile");
      }
    },  
    async addBtn() {
      this.loading = true;
      for(let ix = 0; ix < this.files.length; ix++)
      {
        let file = this.files[ix];
        this.fileStatus[ix].percent = 50;      
        let formData = new FormData();
        formData.append("file", this.files[ix]);
        formData.append("name", file.name);
        formData.append("folder_uuid", this.folder_uuid);
        formData.append("user_uuid", this.user_uuid);
        let self = this;
        this.$http
          .post(this.user.hostname + "/filestorage/file/add", formData)
          .then(() => {
            self.fileStatus[ix].percent = 100;
            self.checkStatus();
          })
          .catch(() => {
            self.checkStatus();
            self.fileStatus[ix].percent = 0;
            self.fileStatus[ix].error = true;
            self.loading = false;
          });
      }   
    },
    filesClass(item) {
      if(!this.ValidateFileSize(item.size))
      {
        return ["is-invalid"];
      } 
      if(item.name.length > 256)
      {
        return ["is-invalid"];
      } 
      if(!this.validateLettersAndNumbers(item.name))
      {
        return ["is-invalid"];
      }
      return ["is-valid"];
    }
  },
  watch: {
    files: function(new_files) {
      this.fileStatus = [];
      for(let ix = 0; ix < new_files.length; ix++)
      {
        this.fileStatus.push({
          index: ix,
          percent: 0,
          error: false
        });
      }
    }
  },
  computed: {
    addDisabled() {
      if(!this.AllValidFilesize){ return true; }
      if(!this.AllValidNames){ return true; }
      return this.files.length == 0;
    },
    AllValidFilesize(){
      for(let ix = 0; ix < this.files.length; ix++)
      {
        if(!this.ValidateFileSize(this.files[ix].size))
        {
          return false;
        }
      }
      return true;
    },
    AllValidNames(){
      for(let ix = 0; ix < this.files.length; ix++)
      {
        if(this.files[ix].name.length > 256)
        {
          return false;
        }
      }
      return true;
    },
    allValidText(){
      for(let ix = 0; ix < this.files.length; ix++)
      {
        if(!this.validateLettersAndNumbers(this.files[ix].name))
        {
          return false;
        }
      }
      return true;
    }
  },
};
</script>
<style>
</style>
