<template>
  <section v-if="files">
    <b-overlay :show="loading" rounded="sm">

      <form class="mt-2">
        <FileSelect v-model="files" max-size="200" class="mt-2" :file-type="fileExtension" v-if="files.length == 0">
          <b-button size="sm" variant="secondary" class="no-border">
            <i class="fal fa-plus"></i> {{ $t("ADD_FILE") }}  ({{files.length}})
          </b-button>
        </FileSelect>
      </form>

      <span v-if="files.length != 0">
        <span
          v-for="(item, index) in files"
          :key="index"
          class="ms-fontSize-12 mt-2"
        >
          <Attachment
            @removeFile="removeFile"
            :is-error="checkError(index)"
            :percent="checkPercent(index)"
            :item="item"
            :index="index"
          ></Attachment>
        </span>
      </span>
      
      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>
  

      <b-button
        :disabled="updateDisabled"
        @click="updateBtn"
        variant="primary"
        class="btn-fill"
        >{{ $t("SAVE") }}</b-button
      >
    </b-overlay>
  </section>
</template>
<script>
import * as blobUtil from "blob-util";
import FileSelect from "../../Compose/FileSelect";
import Attachment from "../../Compose/Attachment";
export default {
  components: {
    FileSelect,
    Attachment,
  },
  props: ["folder_uuid","file_uuid","information","user_uuid", "show"],
  data() {
    return {
      files: [],
      fileStatus: [],
      loading: false,
      file_bytes: null,
      name: "",
    };
  },
  methods: {
    checkError()
    {      
      return false;
    },
    checkPercent()
    {
      //console.log(index);
      return 0;
    },
    closeBtn() {
      this.$emit("close");
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async getFileContent(file) {
      return new Promise((resolve) => {
        blobUtil
          .blobToArrayBuffer(file)
          .then(function (arrayBuffer) {
            var fileByteArray = [];
            let array = new Uint8Array(arrayBuffer);
            for (var i = 0; i < array.length; i++) {
              fileByteArray.push(array[i]);
            }
            resolve(fileByteArray);
          })
          .catch(function () {
            resolve(null);
          });
      });
    },
    async updateBtn() {
      this.loading = true;
      this.fileStatus[0].percent = 50;      
      let formData = new FormData();
      formData.append("file", this.files[0]);
      formData.append("name", this.information.name);
      formData.append("folder_uuid", this.information.folder_uuid);
      formData.append("file_uuid", this.file_uuid);
      formData.append("user_uuid", this.user_uuid);
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/file/update", formData)
        .then(() => {
          self.fileStatus[0].percent = 100;
          self.$emit("addedNewVersion");
          self.$emit("updatedInfo");
        })
        .catch(() => {
          self.fileStatus[0].percent = 0;
          self.fileStatus[0].error = true;
          self.loading = false;
        });
    },
  },
  watch: {
    files: function(new_files) {
      this.fileStatus = [];
      for(let ix = 0; ix < new_files.length; ix++)
      {
        this.fileStatus.push({
          index: ix,
          percent: 0,
          error: false
        });
      }
    }
  },
  computed: {
    fileExtension(){
      let str = this.information.name;
      return str.slice(str.lastIndexOf("."));
    },
    updateDisabled() {
      return this.files.length == 0;
    },
  },
};
</script>
<style>
</style>
