var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        [
          _c("div", { staticClass: "mt-2 clearfix" }, [
            _c("dl", [
              _c("dt", [_vm._v(_vm._s(_vm.$t("NAME")))]),
              _c("dd", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    attrs: { title: _vm.information.name },
                  },
                  [_vm._v(_vm._s(_vm.Truncate(_vm.information.name, 55)))]
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("CREATED")))]),
              _c("dd", [
                _vm._v(
                  _vm._s(_vm.FormatDateTime(_vm.information.created_date))
                ),
              ]),
              _c("dt", [_vm._v(_vm._s(_vm.$t("UUID")))]),
              _c("dd", [_vm._v(_vm._s(_vm.information.folder_uuid))]),
            ]),
          ]),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c("b-button", { on: { click: _vm.closeBtn } }, [
            _vm._v(_vm._s(_vm.$t("CLOSE"))),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }