var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c("b-table", {
                key: "versions_" + _vm.redrawKey,
                staticClass: "mt-2 ml-0 pl-0",
                attrs: {
                  small: "",
                  bordered: "",
                  items: _vm.items,
                  hover: "",
                  fields: _vm.fields,
                  busy: _vm.loading,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(created_date)",
                      fn: function (data) {
                        return [
                          data.item.name != ".."
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormatDateTime(data.item.created_date)
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(created_by)",
                      fn: function (data) {
                        return [
                          data.item.created_by
                            ? _c("UserInfo", {
                                attrs: { user_uuid: data.item.created_by },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(id)",
                      fn: function (data) {
                        return [
                          data.item.name != ".."
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("CREATED")) +
                                    " " +
                                    _vm._s(
                                      _vm.FormatDateTime(data.item.created_date)
                                    )
                                ),
                              ])
                            : _vm._e(),
                          data.item.created_by
                            ? _c("UserInfo", {
                                attrs: { user_uuid: data.item.created_by },
                              })
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(size)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.bytesToSize(data.item.size)) +
                              "\n    "
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(version)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            "\n      " + _vm._s(data.item.version) + "\n    "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  907936986
                ),
              }),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _c("b-button", { on: { click: _vm.closeBtn } }, [
                _vm._v(_vm._s(_vm.$t("CLOSE"))),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }