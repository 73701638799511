var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c("section", { key: _vm.updatedInfoKey }, [
        _c(
          "div",
          { key: _vm.information.id, staticClass: "mt-2" },
          [
            _c("h3", [
              _vm._v("\n      " + _vm._s(_vm.information.name) + "\n    "),
            ]),
            _c(
              "b-nav",
              { staticClass: "hidden-mobile", attrs: { tabs: "" } },
              [
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 0,
                      disabled: _vm.checkDisabled(0),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 0
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("FILESTORAGE.SETTINGS")))]
                ),
                1 == 0
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 1,
                          disabled: _vm.checkDisabled(1),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.MOVE_FOLDER")))]
                    )
                  : _vm._e(),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 2,
                      disabled: _vm.checkDisabled(2),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 2
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("FILESTORAGE.SHARES")))]
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 3,
                      disabled: _vm.checkDisabled(3),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 3
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                ),
              ],
              1
            ),
            _c(
              "b-dropdown",
              {
                staticClass: "hidden-not-mobile",
                attrs: { id: "dropdown-1" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm.showIndex == 0
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("FILESTORAGE.SETTINGS"))),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 1 && 1 == 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("FILESTORAGE.MOVE_FOLDER"))
                                ),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 2
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("FILESTORAGE.SHARES"))),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 3
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("INFORMATION"))),
                              ])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1327160405
                ),
              },
              [
                _c(
                  "b-dropdown-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 0,
                      disabled: _vm.checkDisabled(0),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 0
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("FILESTORAGE.SETTINGS")))]
                ),
                1 == 0
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 1,
                          disabled: _vm.checkDisabled(1),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.MOVE_FOLDER")))]
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 2,
                      disabled: _vm.checkDisabled(2),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 2
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("FILESTORAGE.SHARES")))]
                ),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 3,
                      disabled: _vm.checkDisabled(3),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 3
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                ),
              ],
              1
            ),
            _vm.showIndex == 0
              ? _c(
                  "div",
                  [
                    _c("EditFolder", {
                      attrs: {
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: {
                        close: _vm.close,
                        cancelEdit: _vm.cancelEdit,
                        edited: _vm.edited,
                        removedFolder: _vm.removedFolder,
                        updatedInfo: _vm.updatedInfo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showIndex == 1 && 1 == 0
              ? _c(
                  "div",
                  [
                    _c("MoveFolder", {
                      attrs: {
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: {
                        close: _vm.close,
                        cancelEdit: _vm.cancelEdit,
                        edited: _vm.edited,
                        updatedInfo: _vm.updatedInfo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showIndex == 2
              ? _c(
                  "div",
                  [
                    _c("ShareFolder", {
                      attrs: {
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: { close: _vm.close, updatedInfo: _vm.updatedInfo },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showIndex == 3
              ? _c(
                  "div",
                  [
                    _c("Information", {
                      attrs: {
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: { close: _vm.close, updatedInfo: _vm.updatedInfo },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }