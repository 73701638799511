<template>
  <section class="mt-2" v-if="information">
    <b-overlay :show="loading" rounded="sm">


      <ListFolders :active="information.folder_uuid" v-model="folder_uuid"></ListFolders>

      <div v-if="!edited" class="mt-2">
        <hr class="p-0 m-0 mt-2 mb-2" />
        <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>        
      </div>
<div v-if="edited" class="mt-2">

  <b-alert show>{{ $t('FILESTORAGE.NOTE_CHANGE_SHARED_FILE') }} </b-alert>

        <hr class="p-0 m-0 mt-2 mb-2" />

        
        
        <b-button @click="cancelBtn">{{ $t("CANCEL") }}</b-button>


        

        <b-button
          :disabled="updateDisabled"
          @click="updateBtn"
          variant="primary"
          class="btn-fill"
          >{{ $t("SAVE") }}</b-button
        >
        </div>
        
    </b-overlay>
  </section>
</template>
<script>
import ListFolders from "../ListFolders";
export default {
  components: { ListFolders },
  props: ["information", "user_uuid"],
  data() {
    return {
      redrawRouterViewIndex: 0,
      loading: false,
      folder_uuid: this.information.folder_uuid
    };
  },
  methods: {
    closeBtn() {
      this.$emit("close");
    },
    cancelBtn(){
      this.name = "";
      this.$emit("cancelEdit");
      this.file_uuid = this.information.file_uuid;
    },
    updateBtn() {
      this.loading = true;
      let formData = new FormData();
      formData.append("name", this.information.name);
      formData.append("folder_uuid", this.folder_uuid + "");
      formData.append("file_uuid", this.information.file_uuid);
      formData.append("user_uuid", this.user_uuid);
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/file/update", formData)
        .then(() => {
          self.loading = false;
          self.$emit("updatedInfo");
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    edited(){
      let edited = this.information.folder_uuid != this.folder_uuid;
      if(edited) { this.$emit('edited'); }
      return edited;
    },    
    redrawRouterViewKey() {
      return "redrawRouterView" + this.redrawRouterViewIndex;
    },
    updateDisabled() {
      return this.folder_uuid == "";
    },
  },
  mounted() {
  },
};
</script>
<style>
</style>
