<template>
  <section v-if="information" :key="updatedInfoKey">
    <div class="mt-2" :key="information.id">
      <h3>
        {{ information.name }}
      </h3>


      <b-nav tabs class="hidden-mobile">      
        <b-nav-item :active="showIndex == 0" @click="showIndex = 0" :disabled="checkDisabled(0)">{{ $t("FILESTORAGE.SETTINGS") }}</b-nav-item>
        <b-nav-item v-if="1 == 0" :active="showIndex == 1" @click="showIndex = 1" :disabled="checkDisabled(1)">{{ $t("FILESTORAGE.MOVE_FOLDER") }}</b-nav-item>
        <b-nav-item :active="showIndex == 2" @click="showIndex = 2" :disabled="checkDisabled(2)">{{ $t("FILESTORAGE.SHARES") }}</b-nav-item>
        <b-nav-item :active="showIndex == 3" @click="showIndex = 3" :disabled="checkDisabled(3)">{{ $t("INFORMATION") }}</b-nav-item>
      </b-nav>
      <b-dropdown id="dropdown-1" class="hidden-not-mobile">
        <template #button-content>
          <span v-if="showIndex == 0">{{ $t("FILESTORAGE.SETTINGS") }}</span>
          <span v-if="showIndex == 1 && (1 == 0)">{{ $t("FILESTORAGE.MOVE_FOLDER") }}</span>
          <span v-if="showIndex == 2">{{ $t("FILESTORAGE.SHARES") }}</span>
          <span v-if="showIndex == 3">{{ $t("INFORMATION") }}</span>
        </template>
        <b-dropdown-item :active="showIndex == 0" @click="showIndex = 0" :disabled="checkDisabled(0)">{{ $t("FILESTORAGE.SETTINGS") }}</b-dropdown-item>
        <b-dropdown-item v-if="1 == 0" :active="showIndex == 1" @click="showIndex = 1" :disabled="checkDisabled(1)">{{ $t("FILESTORAGE.MOVE_FOLDER") }}</b-dropdown-item>
        <b-dropdown-item :active="showIndex == 2" @click="showIndex = 2" :disabled="checkDisabled(2)">{{ $t("FILESTORAGE.SHARES") }}</b-dropdown-item>
        <b-dropdown-item :active="showIndex == 3" @click="showIndex = 3" :disabled="checkDisabled(3)">{{ $t("INFORMATION") }}</b-dropdown-item>
      </b-dropdown>

      <div v-if="showIndex == 0">
        <EditFolder @close="close" @cancelEdit="cancelEdit" @edited="edited" @removedFolder="removedFolder" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></EditFolder>
      </div>
      <div v-if="showIndex == 1 && (1 == 0)">
        <MoveFolder  @close="close" @cancelEdit="cancelEdit" @edited="edited" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></MoveFolder>
      </div>
      <div v-if="showIndex == 2">
        <ShareFolder @close="close" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></ShareFolder>
      </div>
      <div v-if="showIndex == 3">
        <Information @close="close" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></Information>
      </div>
    </div>
  </section>
</template>
<script>
import EditFolder from "./EditFolder";
import Information from "./Information";
import MoveFolder from "./MoveFolder";
import ShareFolder from "./ShareFolder";
export default {
  props: {
    start_tab: {
      type: Number,
      default: 0
    },
    folder_uuid: {
      type: String,
      default: null,
    },
    user_uuid: {
      type: String,
      default: null,
    },
  },
  components: { Information, EditFolder, MoveFolder, ShareFolder },
  data() {
    return {
      showIndex: this.start_tab,
      isEdited: false,
      updatedInfoIndex: 0,
      information: null,
    };
  },
  methods: {
    checkDisabled(checkshowIndex)
    {
      if(this.isEdited)
      {
        if(this.showIndex != checkshowIndex)
        {
          return true;
        }
      }
      return false;
    },
    close: function(){
      this.$emit('closeUpdateFolder');
    },
    edited(){
      this.isEdited = true;
    },
    cancelEdit(){
      if(this.isEdited)
      {
        this.isEdited = false;
        this.GetFolderInformation();
      }
    },
    updatedInfo: function () {
      this.GetFolderInformation();
      this.cancelEdit();
      this.$emit('updatedInfo');
    },
    removedFolder: function(){
      this.$emit('removedFolder');
    },
    updatedUsers: function () {},
    removedBox: function () {
      this.$emit("removedBox");
    },
    GetFolderInformation: function () {
      let self = this;
      self.loading = true;
      let GetFolderRequestDTO = {
        user_uuid: this.user_uuid,
        folder_uuid: this.folder_uuid,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/info",
          GetFolderRequestDTO
        )
        .then((response) => {
          self.loading = false;
          self.updatedInfoIndex++;
          self.information = response.data;
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  watch: {
    folder_uuid: function (newVal, oldVal) {
      if (newVal != oldVal) {
       
            this.GetFolderInformation();
      }
    }
  },
  computed: {
    updatedInfoKey(){
      return "updateInfo" + this.updatedInfoIndex;
    },
  },
  mounted() {
    this.showIndex = this.start_tab;
    this.GetFolderInformation();
  },
};
</script>
<style></style>
