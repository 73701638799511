<template>
  <section class="mt-2" v-if="information">

    <b-overlay :show="loading" rounded="sm" v-if="UpdateFolderRequestDTO.name != ''">

      <ListFolders :active="information.parent_uuid" v-model="UpdateFolderRequestDTO.parent_uuid"></ListFolders>

      <div v-if="!edited" class="mt-2">        
        <hr class="p-0 m-0 mt-2 mb-2" />
        <b-button @click="closeBtn" v-if="!edited">{{ $t("CLOSE") }}</b-button>
      </div>

<div v-if="edited" class="mt-2">
      <b-alert show >{{ $t('FILESTORAGE.NOTE_CHANGE_SHARED') }} </b-alert>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button @click="cancelBtn">{{ $t("CANCEL") }}</b-button>

      <b-button
          :disabled="updateDisabled"        
          @click="updateBtn"
          variant="primary"
          class="btn-fill"
          >{{ $t("SAVE") }}</b-button
        >
      </div>
    </b-overlay>
  </section>
</template>
<script>
import ListFolders from "../ListFolders";
export default {
  components: {  ListFolders },
  props: ["information", "user_uuid"],
  data() {
    return {
      redrawRouterViewIndex: 0,
      loading: false,
      UpdateFolderRequestDTO: {
        user_uuid: "",
        folder_uuid: "",
        parent_uuid: "",
        remove: 0,
        name: ""
      }
    };
  },
  methods: {
    closeBtn() {
      this.$emit("close");
    },
    cancelBtn(){
      this.name = "";
      this.$emit("cancelEdit");
      this.UpdateFolderRequestDTO.parent_uuid = this.information.parent_uuid;
    },
    updateBtn() {
      this.loading = true;
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/update",
          this.UpdateFolderRequestDTO
        )
        .then(() => {
          self.loading = false;
          self.$emit("updatedInfo");
          self.name = "";
        })
        .catch(() => {
          self.loading = false;
        });
    }
  },
  computed: {
    edited(){
      let edited = this.information.parent_uuid != this.UpdateFolderRequestDTO.parent_uuid;
      if(edited) { this.$emit('edited'); }
      return edited;
    },    
    redrawRouterViewKey() {
      return "redrawRouterView" + this.redrawRouterViewIndex;
    },
    updateDisabled() {
      return this.information.parent_uuid == this.UpdateFolderRequestDTO.parent_uuid;
    },
  },
  mounted() {
    this.UpdateFolderRequestDTO = {
      user_uuid: this.user_uuid,
      folder_uuid: this.information.folder_uuid,
      parent_uuid: this.information.parent_uuid,
      remove: 0,
      name: this.information.name,
    };
  },
};
</script>
<style>
</style>
