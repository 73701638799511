<template>
  <section class="mt-2" v-if="information">
    <b-overlay :show="loading" rounded="sm">

      <b-form-group label-cols-md="4" :label="$t('NAME')">
        <b-form-input trim :state="stateName"  v-on:keypress="isLettersAndNumbers($event)"
          :placeholder="$t('NAME')"
          v-model="name"
        ></b-form-input>
      </b-form-group>

      
      <div class="mt-2 error-text" v-if="!isValidText">
        {{ $t("ERROR.CHARACTERS_NOT_ALLOWED") }} {{  notAllowedCharacters }}
      </div>

      <div class="mt-2 error-text" v-if="name.length > 256">
        {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
      </div>
      <div class="text-right">
        <small class="pull-right">{{ name.length }}/256</small>
      </div>
     
      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button @click="cancelBtn" v-if="edited">{{ $t("CANCEL") }}</b-button>
      <b-button @click="closeBtn" v-if="!edited">{{ $t("CLOSE") }}</b-button>


      <b-button
        :disabled="updateDisabled"
        @click="updateBtn"
        variant="primary"
        class="btn-fill"
        >{{ $t("SAVE") }}</b-button
      >

     
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: {
    information: {
      type: Object,
      default: null
    },
    folder_information: {
      type: Object,
      default: null
    },
    user_uuid: {
      type: String,
      default: null,
    },
    clickRemove: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      edited: false,
      loading: false,
      name: ""
    };
  },
  methods: {
    removeBtn: function () {
      let self = this;
      let RemoveFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: this.information.file_uuid
      };
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          dialogClass: this.teams.theme,
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/filestorage/file/remove",
                RemoveFileRequestDTO
              )
              .then(() => {
                self.$emit("removedFile");
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
    cancelBtn() {
      this.name = this.information.name;
      this.$emit("cancelEdit");
    },
    closeBtn() {
      this.$emit("close");
    },
    updateBtn() {
      this.loading = true;
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("folder_uuid", this.information.folder_uuid + "");
      formData.append("file_uuid", this.information.file_uuid);
      formData.append("user_uuid", this.user_uuid);
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/file/update", formData)
        .then(() => {
          self.loading = false;
          self.$emit("updatedInfo");
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  computed: {
    updateDisabled() {
      return this.name == this.information.name || this.name == "" || this.name.length > 256 || !this.validateLettersAndNumbers(this.name);
    },
    isValidText(){
      return this.name == "" || this.validateLettersAndNumbers(this.name);
    },
    stateName(){
      if(this.name == this.information.name)
      {
        return null;
      }
      return !this.updateDisabled;
    }  
  },
  watch: {
    name: function() {
      if(this.name != this.information.name)
      {
        this.edited = true;
        this.$emit('edited');
      }
    },
  },
  mounted() {
    if(this.clickRemove)
    {
      this.removeBtn();
    }
    this.name = this.information.name;
  },
};
</script>
<style>
</style>
