<template>
  <section v-if="information">
    <div class="mt-2 clearfix">
      
      <dl>
        <dt>{{ $t("NAME") }}</dt>
        <dd><span v-b-tooltip.hover :title="information.name">{{ Truncate(information.name,55) }}</span></dd>
        <dt>{{ $t("VERSION") }}</dt>
        <dd>{{ currentVersion.version }}</dd>     
        <dt>{{ $t("EDITED_BY") }}</dt>
        <dd><UserInfo name-only v-if="information.created_by"
          :user_uuid="information.created_by"
        ></UserInfo></dd>
        <dd></dd>        
        <dt>{{ $t('CREATED') }}</dt>
        <dd>{{ FormatDateTime(currentVersion.created_date) }}</dd>
        <dt>{{ $t('UUID') }}</dt>
        <dd>{{ information.file_uuid }}</dd> 
        <dt v-if="folder_information">{{ $t("FILESTORAGE.FOLDER") }}</dt>
        <dd v-if="folder_information"><span v-b-tooltip.hover :title="folder_information.name">{{ Truncate(folder_information.name,55) }}</span></dd>   
        <dt>{{ $t('SIZE') }}</dt>
        <dd>{{ bytesToSize(currentVersion.size) }}</dd> 
      </dl>

    </div>

    <hr class="p-0 m-0 mt-2 mb-2" />

    <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>


  </section>
</template>
<script>
import UserInfo from "../../Layout/UserInfo";
export default {
  components: { UserInfo },
  props: ["information", "currentVersion", "folder_information"],
  data() {
    return {    
    };
  },
  methods: {    
    closeBtn() {
      this.$emit("close");
    },
  },
  mounted: function() {
  }
};
</script>
<style>
</style>