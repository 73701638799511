<template>
  <section class="mt-2" v-if="information && currentVersion.version && !loading">

    <b-overlay :show="loading" rounded="sm">

      <div v-if="information.version == currentVersion.version && current_content">
        <b-form-textarea
          class="mt-2 textarea"
          id="messageContent"
          input-id="message"
          ref="messageContent"
          v-model="current_content"
          autocomplete="off"
          :placeholder="$t('CONTENT')"
          rows="15"
          :state="currentContentLength < maxLength"
          max-rows="15"
        ></b-form-textarea>        
        <b-form-invalid-feedback v-if="currentContentLength > maxLength">
          {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
        </b-form-invalid-feedback>
        <div class="text-right">
          <small class="pull-right">{{ currentContentLength }}/{{maxLength}}</small>
        </div>

        <hr class="p-0 m-0 mt-2 mb-2" />

        <b-button @click="cancelBtn" v-if="edited">{{ $t("CANCEL") }}</b-button>
        <b-button @click="closeBtn" v-if="!edited">{{ $t("CLOSE") }}</b-button>


        <b-button
          :disabled="updateDisabled"
          @click="updateBtn"
          variant="primary"
          class="btn-fill"
          >{{ $t("SAVE") }}</b-button
        >
      </div>
      <div v-if="information.version != currentVersion.version">

        <b-form-textarea
          plaintext
          readonly
          class="mt-2 textarea"
          id="messageContent"
          input-id="message"
          ref="messageContent"
          v-model="content"
          rows="15"
          max-rows="15"
        ></b-form-textarea>
        <hr class="p-0 m-0 mt-2 mb-2" />

        <b-button
          @click="resetThisVersion"
          variant="primary"
          class="btn-fill"
          >{{ $t("FILESTORAGE.RESET_TO_THIS_VERSION") }}</b-button
        >
      </div>

    </b-overlay>
  </section>
</template>
<script>
export default {
  props: {
    information: {
      type: Object,
      default: null,
    },
    currentVersion: {
      type: Object,
      default: null,
    },
    folder_information: {
      type: Object,
      default: null,
    },
    user_uuid: {
      type: String,
      default: null,
    },
    file_uuid: {
      type: String,
      default: null,
    },
    clickRemove: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      maxLength: 20000,
      current_content: "",
      content: "",
      edited: false,
      loading: true,
      name: "",
    };
  },
  methods: {
    GetFileContent: function () {
      let self = this;
      self.loading = true;
      let GetFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: this.file_uuid,
        version: this.currentVersion.version
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/file/get",
          GetFileRequestDTO
        )
        .then((response) => {
          self.loading = false;
          self.content = response.data;
          self.current_content = response.data;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    cancelBtn() {
      this.current_content = this.content;
      this.$emit("cancelEdit");
    },
    closeBtn() {
      this.$emit("close");
    },
    updateBtn() {
      this.loading = true;
      let formData = new FormData();
      var dataBlob = new Blob([this.current_content], { type: "text/plain" });
      let file = new File([dataBlob], this.name + ".txt");
      formData.append("file", file);
      formData.append("name", this.information.name);
      formData.append("folder_uuid", this.information.folder_uuid);
      formData.append("file_uuid", this.information.file_uuid);
      formData.append("user_uuid", this.user_uuid);
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/file/update", formData)
        .then(() => {
          self.$emit("updatedInfo");
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    resetThisVersion(){
      let self = this;
      let ResetFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: this.file_uuid,
        version: this.currentVersion.version
      };
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          dialogClass: this.teams.theme,
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function (value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/filestorage/file/reset",
                ResetFileRequestDTO
              )
              .then(() => {
                self.$emit("updatedInfo");
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function () {});
    },
  },
  computed: {
    currentContentLength(){
      return (this.current_content + "").length;
    },
    updateDisabled() {
      if(this.currentContentLength > this.maxLength)
      {
        return true;
      }
      return this.content == this.current_content;
    },
  },
  watch: {
    currentVersion: function(){
      this.GetFileContent();
    },
    current_content: function () {
      if (this.content != this.current_content) {
        this.edited = true;
        this.$emit("edited");
      }
    },
  },
  mounted() {
    this.GetFileContent();
  },
};
</script>
<style>
</style>
