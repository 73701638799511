var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information && _vm.folder_information && _vm.currentVersion.version
    ? _c("section", { key: _vm.updatedInfoKey }, [
        _c(
          "div",
          { key: _vm.information.id, staticClass: "mt-2" },
          [
            _c(
              "div",
              { staticClass: "fileTitle" },
              [
                _c(
                  "b-dropdown",
                  {
                    key: _vm.redrawVersionsKey,
                    attrs: {
                      size: "lg",
                      disabled: _vm.isEdited,
                      "toggle-class": "text-decoration-none text-left",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", { class: _vm.iconClass }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.information.name) +
                                  " (v." +
                                  _vm._s(_vm.currentVersion.version) +
                                  ")\n        "
                              ),
                              _c(
                                "small",
                                [
                                  _c("br"),
                                  _vm._v(_vm._s(_vm.$t("EDITED_BY")) + " "),
                                  _vm.information.created_by
                                    ? _c("UserInfo", {
                                        attrs: {
                                          "name-only": "",
                                          user_uuid: _vm.information.created_by,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2525245485
                    ),
                  },
                  _vm._l(_vm.versions, function (versionItem, index) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: index,
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.setCurrentVersion(versionItem)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        v." +
                            _vm._s(versionItem.version) +
                            "\n      "
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _c(
                  "b-button",
                  { attrs: { size: "sm" }, on: { click: _vm.fileDownload } },
                  [_vm._v(_vm._s(_vm.$t("DOWNLOAD")) + " ")]
                ),
              ],
              1
            ),
            _c(
              "b-nav",
              { staticClass: "hidden-mobile", attrs: { tabs: "" } },
              [
                _vm.section != "APPLICATION"
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 0,
                          disabled: _vm.checkDisabled(0),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 0
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.SETTINGS")))]
                    )
                  : _vm._e(),
                !_vm.editable && _vm.section != "APPLICATION"
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 1,
                          disabled: _vm.checkDisabled(1),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.NEW_VERSION")))]
                    )
                  : _vm._e(),
                _vm.editable && _vm.section != "APPLICATION"
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 1,
                          disabled: _vm.checkDisabled(1),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.CONTENT")))]
                    )
                  : _vm._e(),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 2,
                      disabled: _vm.checkDisabled(2),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 2
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("FILESTORAGE.VERSIONS")))]
                ),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 3,
                      disabled: _vm.checkDisabled(3),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 3
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                ),
                1 == 0
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 4,
                          disabled: _vm.checkDisabled(4),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 4
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.MOVE_FILE")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "b-dropdown",
              {
                staticClass: "hidden-not-mobile",
                attrs: { id: "dropdown-1" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm.showIndex == 0
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("FILESTORAGE.SETTINGS"))),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 1 && !_vm.editable
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("FILESTORAGE.NEW_VERSION"))
                                ),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 1 && _vm.editable
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("FILESTORAGE.CONTENT"))),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 2
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("FILESTORAGE.VERSIONS"))),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 3
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("INFORMATION"))),
                              ])
                            : _vm._e(),
                          _vm.showIndex == 4 && 1 == 0
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("FILESTORAGE.MOVE_FILE"))),
                              ])
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  170994214
                ),
              },
              [
                _vm.section != "APPLICATION"
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 0,
                          disabled: _vm.checkDisabled(0),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 0
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.SETTINGS")))]
                    )
                  : _vm._e(),
                !_vm.editable && _vm.section != "APPLICATION"
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 1,
                          disabled: _vm.checkDisabled(1),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.NEW_VERSION")))]
                    )
                  : _vm._e(),
                _vm.editable && _vm.section != "APPLICATION"
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 1,
                          disabled: _vm.checkDisabled(1),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 1
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.CONTENT")))]
                    )
                  : _vm._e(),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 2,
                      disabled: _vm.checkDisabled(2),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 2
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("FILESTORAGE.VERSIONS")))]
                ),
                _c(
                  "b-dropdown-item",
                  {
                    attrs: {
                      active: _vm.showIndex == 3,
                      disabled: _vm.checkDisabled(3),
                    },
                    on: {
                      click: function ($event) {
                        _vm.showIndex = 3
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("INFORMATION")))]
                ),
                1 == 0
                  ? _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          active: _vm.showIndex == 4,
                          disabled: _vm.checkDisabled(4),
                        },
                        on: {
                          click: function ($event) {
                            _vm.showIndex = 4
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("FILESTORAGE.MOVE_FILE")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.showIndex == 0
              ? _c(
                  "div",
                  [
                    _c("Edit", {
                      attrs: {
                        file_uuid: _vm.file_uuid,
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: {
                        cancelEdit: _vm.cancelEdit,
                        close: _vm.close,
                        edited: _vm.edited,
                        removedFolder: _vm.removedFolder,
                        updatedInfo: _vm.updatedInfo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showIndex == 1
              ? _c(
                  "div",
                  [
                    !_vm.editable
                      ? _c("UpdateVersion", {
                          attrs: {
                            file_uuid: _vm.file_uuid,
                            user_uuid: _vm.user_uuid,
                            information: _vm.information,
                          },
                          on: {
                            close: _vm.close,
                            cancelEdit: _vm.cancelEdit,
                            edited: _vm.edited,
                            addedNewVersion: _vm.addedNewVersion,
                            updatedInfo: _vm.updatedInfo,
                          },
                        })
                      : _vm._e(),
                    _vm.isTxt
                      ? _c("FileTypeTxt", {
                          attrs: {
                            currentVersion: _vm.currentVersion,
                            file_uuid: _vm.file_uuid,
                            user_uuid: _vm.user_uuid,
                            information: _vm.information,
                          },
                          on: {
                            close: _vm.close,
                            cancelEdit: _vm.cancelEdit,
                            edited: _vm.edited,
                            updatedInfo: _vm.updatedInfo,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showIndex == 2
              ? _c(
                  "div",
                  [
                    _c("Versions", {
                      attrs: {
                        file_uuid: _vm.file_uuid,
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: {
                        cancelEdit: _vm.cancelEdit,
                        close: _vm.close,
                        edited: _vm.edited,
                        removedFolder: _vm.removedFolder,
                        updatedInfo: _vm.updatedInfo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showIndex == 3
              ? _c(
                  "div",
                  [
                    _c("Information", {
                      attrs: {
                        currentVersion: _vm.currentVersion,
                        file_uuid: _vm.file_uuid,
                        folder_information: _vm.folder_information,
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: {
                        cancelEdit: _vm.cancelEdit,
                        close: _vm.close,
                        edited: _vm.edited,
                        updatedInfo: _vm.updatedInfo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showIndex == 4
              ? _c(
                  "div",
                  [
                    _c("MoveFile", {
                      attrs: {
                        currentVersion: _vm.currentVersion,
                        file_uuid: _vm.file_uuid,
                        folder_information: _vm.folder_information,
                        user_uuid: _vm.user_uuid,
                        information: _vm.information,
                      },
                      on: {
                        cancelEdit: _vm.cancelEdit,
                        close: _vm.close,
                        edited: _vm.edited,
                        updatedInfo: _vm.updatedInfo,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }