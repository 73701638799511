<template>
  <section v-if="information && folder_information && currentVersion.version" :key="updatedInfoKey">
    <div class="mt-2" :key="information.id">
    
      <div class="fileTitle">
      <b-dropdown :key="redrawVersionsKey" size="lg" :disabled="isEdited" toggle-class="text-decoration-none text-left" >
        <template #button-content>
          <i :class="iconClass"></i> {{ information.name }} (v.{{ currentVersion.version }})
          <small><br />{{ $t('EDITED_BY') }} <UserInfo name-only v-if="information.created_by"
           :user_uuid="information.created_by"
         ></UserInfo></small> 
        </template>
        <b-dropdown-item @click="setCurrentVersion(versionItem)" v-for="(versionItem,index) in versions" :key="index" href="#">
          v.{{versionItem.version}}
        </b-dropdown-item>
      </b-dropdown>
      <b-button size="sm" @click="fileDownload">{{ $t('DOWNLOAD')}} </b-button>
      </div>
      
      <b-nav tabs class="hidden-mobile">      
        <b-nav-item v-if="section != 'APPLICATION'" :active="showIndex == 0" @click="showIndex = 0" :disabled="checkDisabled(0)">{{ $t("FILESTORAGE.SETTINGS") }}</b-nav-item>
        <b-nav-item v-if="!editable && section != 'APPLICATION'" :active="showIndex == 1" @click="showIndex = 1" :disabled="checkDisabled(1)">{{ $t("FILESTORAGE.NEW_VERSION") }}</b-nav-item>
        <b-nav-item v-if="editable && section != 'APPLICATION'" :active="showIndex == 1" @click="showIndex = 1" :disabled="checkDisabled(1)">{{ $t("FILESTORAGE.CONTENT") }}</b-nav-item>
        <b-nav-item :active="showIndex == 2" @click="showIndex = 2" :disabled="checkDisabled(2)">{{ $t("FILESTORAGE.VERSIONS") }}</b-nav-item>
        <b-nav-item :active="showIndex == 3" @click="showIndex = 3" :disabled="checkDisabled(3)">{{ $t("INFORMATION") }}</b-nav-item>
        <b-nav-item v-if="1 == 0" :active="showIndex == 4" @click="showIndex = 4" :disabled="checkDisabled(4)">{{ $t("FILESTORAGE.MOVE_FILE") }}</b-nav-item>
      </b-nav>
      <b-dropdown id="dropdown-1" class="hidden-not-mobile">
        <template #button-content>
          <span v-if="showIndex == 0">{{ $t("FILESTORAGE.SETTINGS") }}</span>
          <span v-if="showIndex == 1 && !editable">{{ $t("FILESTORAGE.NEW_VERSION") }}</span>
          <span v-if="showIndex == 1 && editable">{{ $t("FILESTORAGE.CONTENT") }}</span>
          <span v-if="showIndex == 2">{{ $t("FILESTORAGE.VERSIONS") }}</span>
          <span v-if="showIndex == 3">{{ $t("INFORMATION") }}</span>
          <span v-if="showIndex == 4 && (1 == 0)">{{ $t("FILESTORAGE.MOVE_FILE") }}</span>
        </template>
        <b-dropdown-item v-if="section != 'APPLICATION'" :active="showIndex == 0" @click="showIndex = 0" :disabled="checkDisabled(0)">{{ $t("FILESTORAGE.SETTINGS") }}</b-dropdown-item>
        <b-dropdown-item v-if="!editable && section != 'APPLICATION'" :active="showIndex == 1" @click="showIndex = 1" :disabled="checkDisabled(1)">{{ $t("FILESTORAGE.NEW_VERSION") }}</b-dropdown-item>
        <b-dropdown-item v-if="editable && section != 'APPLICATION'" :active="showIndex == 1" @click="showIndex = 1" :disabled="checkDisabled(1)">{{ $t("FILESTORAGE.CONTENT") }}</b-dropdown-item>
        <b-dropdown-item :active="showIndex == 2" @click="showIndex = 2" :disabled="checkDisabled(2)">{{ $t("FILESTORAGE.VERSIONS") }}</b-dropdown-item>
        <b-dropdown-item :active="showIndex == 3" @click="showIndex = 3" :disabled="checkDisabled(3)">{{ $t("INFORMATION") }}</b-dropdown-item>
        <b-dropdown-item v-if="1 == 0" :active="showIndex == 4" @click="showIndex = 4" :disabled="checkDisabled(4)">{{ $t("FILESTORAGE.MOVE_FILE") }}</b-dropdown-item>
      </b-dropdown>


      <div v-if="showIndex == 0">
        <Edit  @cancelEdit="cancelEdit" @close="close" @edited="edited" :file_uuid="file_uuid" @removedFolder="removedFolder" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></Edit>
      </div>
      <div v-if="showIndex == 1">
        <UpdateVersion v-if="!editable"  @close="close" @cancelEdit="cancelEdit" @edited="edited" :file_uuid="file_uuid" @addedNewVersion="addedNewVersion" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></UpdateVersion>        
        <FileTypeTxt v-if="isTxt"  @close="close" @cancelEdit="cancelEdit"  @edited="edited" :currentVersion="currentVersion" :file_uuid="file_uuid" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></FileTypeTxt>        
      </div>
      <div v-if="showIndex == 2">
        <Versions  @cancelEdit="cancelEdit"  @close="close" @edited="edited" :file_uuid="file_uuid" @removedFolder="removedFolder" @updatedInfo="updatedInfo" :user_uuid="user_uuid" :information="information"></Versions>
      </div>
      <div v-if="showIndex == 3">
        <Information @cancelEdit="cancelEdit" @close="close" @edited="edited" :currentVersion="currentVersion" :file_uuid="file_uuid" @updatedInfo="updatedInfo" :folder_information="folder_information" :user_uuid="user_uuid"  :information="information"></Information>
      </div>
      <div v-if="showIndex == 4">
        <MoveFile @cancelEdit="cancelEdit" @close="close" @edited="edited" :currentVersion="currentVersion" :file_uuid="file_uuid" @updatedInfo="updatedInfo" :folder_information="folder_information" :user_uuid="user_uuid"  :information="information"></MoveFile>
      </div>
    </div>
  </section>
</template>
<script>
import UserInfo from "../../Layout/UserInfo";
import Information from "./Information";
import Edit from "./Edit";
import FileTypeTxt from "./FileType/Txt";
import UpdateVersion from "./UpdateVersion";
import MoveFile from "./MoveFile";
import Versions from "./Versions";
export default {
  props: {
    section: {
      type: String,
      default: 'FILES'
    },
    start_tab: {
      type: Number,
      default: 0
    },
    folder_uuid: {
      type: String,
      default: "",
    },
    file_uuid: {
      type: String,
      default: "",
    },
    user_uuid: {
      type: String,
      default: "",
    },
  },
  components: { UserInfo, Information, FileTypeTxt, Edit, UpdateVersion, Versions, MoveFile },
  data() {
    return {
      updated: false,
      versions: [],
      currentVersion: 0,
      showIndex: this.start_tab,
      isEdited: false,
      updatedInfoIndex: 0,
      information: null,
      folder_information: null,
      redrawVersionsIndex: 0
    };
  },
  methods: {
    async fileDownload(){   
      let GetFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: this.file_uuid,
        version: this.currentVersion.version
      };      
      let FileItemDTO = await this.$http.post(this.user.hostname + "/filestorage/file/info", GetFileRequestDTO);
      let FileBlob = await this.$http.post(this.user.hostname + "/filestorage/file/get", GetFileRequestDTO, {responseType: 'blob'} );
      this.downloadBlob(FileBlob.data, FileItemDTO.data.name);
    },
    setCurrentVersion(versionItem)
    {
      this.currentVersion = versionItem;
    },
    checkDisabled(checkshowIndex)
    {
      if(this.isEdited)
      {
        if(this.showIndex != checkshowIndex)
        {
          return true;
        }
      }
      return false;
    },
    edited(){
      this.isEdited = true;
    },
    cancelEdit(){
      if(this.isEdited)
      {
        this.updatedInfoIndex++;
        this.isEdited = false;
      }
    },
    addedNewVersion(){
      this.GetFileInformation();
      this.cancelEdit();
      this.$emit('updatedInfo');
      this.showIndex = 2;
    },
    updatedInfo: function () {
      this.GetFileInformation();
      this.updated = true;
      this.cancelEdit();
    },
    removedFolder: function(){
      this.$emit('removedFile');
    },
    close: function(){
      if(this.updated)
      {        
        this.$emit('updatedInfo');
      }
      this.$emit('closeUpdateFile');
    },
    updatedUsers: function () {},
    GetFileInformation: function () {
      let self = this;
      self.loading = true;
      let GetFileRequestDTO = {
        user_uuid: this.user_uuid,
        file_uuid: this.file_uuid,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/file/info",
          GetFileRequestDTO
        )
        .then((response) => {
          self.loading = false;
          self.updatedInfoIndex++;
          self.information = response.data;
          self.GetFolderInformation();
          self.ListVersions();
        })
        .catch(() => {
          self.loading = false;
        });
    },
    ListVersions: function () {
      let self = this;
      self.loading = true;
      let ListFileVersionsRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: this.user_uuid,
        file_uuid: this.file_uuid,
        search_text: "",
        page: 1,
        limit: 2000        
      }
      this.$http
        .post(
          this.user.hostname + "/filestorage/file/versions/list",
          ListFileVersionsRequestDTO
        )
        .then((response) => {
          self.loading = false;
          self.versions = response.data.items;
          self.currentVersion = response.data.items[0];
          self.redrawVersionsIndex++;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    GetFolderInformation: function () {
      if(this.folder_uuid)
      {
        let self = this;
        self.loading = true;
        let GetFolderRequestDTO = {
          user_uuid: this.user_uuid,
          folder_uuid: this.folder_uuid,
        };
        this.$http
          .post(
            this.user.hostname + "/filestorage/folder/info",
            GetFolderRequestDTO
          )
          .then((response) => {
            self.loading = false;
            self.updatedInfoIndex++;
            self.folder_information = response.data;
          })
          .catch(() => {
            self.loading = false;
          });
      }else{
        this.folder_information = {};
      }
    }
  },
  watch: {
    file_uuid: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if(newVal != undefined)
        {
          if(newVal != "")
          {
            this.GetFileInformation();
          }
        }
      }
    },
    folder_uuid: function (newVal, oldVal) {
      if (newVal != oldVal) {
        if(newVal != undefined)
        {
          if(newVal != "")
          {
            this.GetFolderInformation();
          }
        }
      }
    }
  },
  computed: {
    editable(){
      return this.isTxt;
    },
    iconClass()
    {
      let icon = this.$const.FileIcons.GetByName(this.information.name);  
      return icon + " ";
    },
    updatedInfoKey(){
      return "updateInfo" + this.updatedInfoIndex;
    },
    isTxt(){
      let extension = this.information.name.slice(this.information.name.lastIndexOf("."));
      if(extension == ".txt" && this.information.size <= 20000)
      {
        return true;
      }
      return false;
    },
    redrawVersionsKey(){
      return "redrawVersions" + this.redrawVersionsIndex; 
    }
  },
  mounted() {
    this.showIndex = this.start_tab;
    this.GetFileInformation();
  },
};
</script>
<style></style>
