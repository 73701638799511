<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      
      <div class="mt-2 mb-2">{{ $t("NAME") }}</div>
      
      <b-form-input trim :placeholder="$t('NAME')" :state="stateName" v-model="name" v-on:keypress="isLettersAndNumbers($event)"></b-form-input>      
     
      <div class="mt-2 error-text" v-if="name.length > 256">
        {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
      </div>

      <div class="mt-2 error-text" v-if="!isValidText">
        {{ $t("ERROR.CHARACTERS_NOT_ALLOWED") }} {{  notAllowedCharacters }}
      </div>
      
      <div class="text-right">
        <small class="pull-right">{{ name.length }}/256</small>
      </div>

      <hr class="p-0 m-0 mt-2 mb-2" />

      <b-button @click="cancelBtn" id="cancelBtn">{{ $t("CLOSE") }}</b-button>

      <b-button
        :disabled="addDisabled"
        @click="addBtn"
        variant="primary"
        id="addBtn"
        class="btn-fill"
        >{{ $t("SAVE") }}</b-button
      >

    </b-overlay>
  </section>
</template>
<script>
export default {
  props: {    
    folder_uuid: {
      default: "",
      type: String,
    },
    user_uuid: {
      default: "",
      type: String,
    }
  },
  data() {
    return {
      loading: false,
      name: "",
    };
  },
  methods: {
    cancelBtn(){
      this.name = "";
      this.$emit('closeAddFolder');
    },
    addBtn(){
      let AddFolderRequestDTO = {
        user_uuid: this.user_uuid,
        parent_uuid: this.folder_uuid,
        name: this.name
      };
      this.loading = true;
      let self = this;
      this.$http
        .post(this.user.hostname + "/filestorage/folder/add", AddFolderRequestDTO)
        .then(() => {
          self.loading = false;
          self.$emit('addedFolder');
          self.name = "";
        })
        .catch(() => {
          self.loading = false;
        });
    }
  },
  computed: {
    addDisabled(){
      return this.name == "" || this.name.length > 256 || !this.validateLettersAndNumbers(this.name);
    },
    isValidText(){
      return this.name == "" || this.validateLettersAndNumbers(this.name);
    },
    stateName(){
      if(this.name == "")
      {
        return null;
      }
      return !this.addDisabled;
    }  
  }
};
</script>
<style>
  </style>
