<template>
  <section v-if="information">
    <div class="mt-2 clearfix">

      <dl>
        <dt>{{ $t("NAME") }}</dt>
        <dd><span v-b-tooltip.hover :title="information.name">{{ Truncate(information.name,55) }}</span></dd>
        <dt>{{ $t('CREATED') }}</dt>
        <dd>{{ FormatDateTime(information.created_date) }}</dd>
        <dt>{{ $t('UUID') }}</dt>
        <dd>{{ information.folder_uuid }}</dd> 
      </dl>

    </div>
    <hr class="p-0 m-0 mt-2 mb-2" />

    <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>

  </section>
</template>
<script>
export default {
  props: ["information", "folder_information"],
  data() {
    return {    
    };
  },
  methods: {   
    closeBtn() {
      this.$emit("close");
    }, 
  },
  mounted: function() {
  }
};
</script>
<style>
</style>