var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.UpdateFolderRequestDTO
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c(
                "b-form-group",
                { attrs: { "label-cols-md": "4", label: _vm.$t("NAME") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      state: _vm.stateName,
                      placeholder: _vm.$t("NAME"),
                    },
                    on: {
                      keypress: function ($event) {
                        return _vm.isLettersAndNumbers($event)
                      },
                    },
                    model: {
                      value: _vm.UpdateFolderRequestDTO.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.UpdateFolderRequestDTO, "name", $$v)
                      },
                      expression: "UpdateFolderRequestDTO.name",
                    },
                  }),
                ],
                1
              ),
              _vm.UpdateFolderRequestDTO.name.length > 256
                ? _c("div", { staticClass: "mt-2 error-text" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              !_vm.isValidText
                ? _c("div", { staticClass: "mt-2 error-text" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("ERROR.CHARACTERS_NOT_ALLOWED")) +
                        " " +
                        _vm._s(_vm.notAllowedCharacters) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "text-right" }, [
                _c("small", { staticClass: "pull-right" }, [
                  _vm._v(
                    _vm._s(_vm.UpdateFolderRequestDTO.name.length) + "/256"
                  ),
                ]),
              ]),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _vm.edited
                ? _c("b-button", { on: { click: _vm.cancelBtn } }, [
                    _vm._v(_vm._s(_vm.$t("CANCEL"))),
                  ])
                : _vm._e(),
              !_vm.edited
                ? _c("b-button", { on: { click: _vm.closeBtn } }, [
                    _vm._v(_vm._s(_vm.$t("CLOSE"))),
                  ])
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { disabled: _vm.updateDisabled, variant: "primary" },
                  on: { click: _vm.updateBtn },
                },
                [_vm._v(_vm._s(_vm.$t("SAVE")))]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }