var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information && _vm.currentVersion.version && !_vm.loading
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _vm.information.version == _vm.currentVersion.version &&
            _vm.current_content
              ? _c(
                  "div",
                  [
                    _c("b-form-textarea", {
                      ref: "messageContent",
                      staticClass: "mt-2 textarea",
                      attrs: {
                        id: "messageContent",
                        "input-id": "message",
                        autocomplete: "off",
                        placeholder: _vm.$t("CONTENT"),
                        rows: "15",
                        state: _vm.currentContentLength < _vm.maxLength,
                        "max-rows": "15",
                      },
                      model: {
                        value: _vm.current_content,
                        callback: function ($$v) {
                          _vm.current_content = $$v
                        },
                        expression: "current_content",
                      },
                    }),
                    _vm.currentContentLength > _vm.maxLength
                      ? _c("b-form-invalid-feedback", [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                              "\n      "
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "text-right" }, [
                      _c("small", { staticClass: "pull-right" }, [
                        _vm._v(
                          _vm._s(_vm.currentContentLength) +
                            "/" +
                            _vm._s(_vm.maxLength)
                        ),
                      ]),
                    ]),
                    _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                    _vm.edited
                      ? _c("b-button", { on: { click: _vm.cancelBtn } }, [
                          _vm._v(_vm._s(_vm.$t("CANCEL"))),
                        ])
                      : _vm._e(),
                    !_vm.edited
                      ? _c("b-button", { on: { click: _vm.closeBtn } }, [
                          _vm._v(_vm._s(_vm.$t("CLOSE"))),
                        ])
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill",
                        attrs: {
                          disabled: _vm.updateDisabled,
                          variant: "primary",
                        },
                        on: { click: _vm.updateBtn },
                      },
                      [_vm._v(_vm._s(_vm.$t("SAVE")))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.information.version != _vm.currentVersion.version
              ? _c(
                  "div",
                  [
                    _c("b-form-textarea", {
                      ref: "messageContent",
                      staticClass: "mt-2 textarea",
                      attrs: {
                        plaintext: "",
                        readonly: "",
                        id: "messageContent",
                        "input-id": "message",
                        rows: "15",
                        "max-rows": "15",
                      },
                      model: {
                        value: _vm.content,
                        callback: function ($$v) {
                          _vm.content = $$v
                        },
                        expression: "content",
                      },
                    }),
                    _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill",
                        attrs: { variant: "primary" },
                        on: { click: _vm.resetThisVersion },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("FILESTORAGE.RESET_TO_THIS_VERSION"))
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }